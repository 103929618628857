import {StaticQuery, graphql} from "gatsby"
import React from "react"
import {Container, Menu, MenuItem, Title, Stripes, Number, GotoMenu} from "../styles/sideMenuStyle"
import StripeImg from "../images/Stripes@2x.png"
import { ROUTES } from "../routes";

const AttachmentMenu = () => (
    <Container>
        <Number>{process.env.GATSBY_PHONE_NUMBER}</Number>
        <Stripes src={StripeImg}/>
        <Title>CUSTOMER SERVICE</Title>
        <Menu>
            <MenuItem>OFFICE: {process.env.GATSBY_PHONE_NUMBER}</MenuItem>
            <MenuItem>{process.env.GATSBY_SERVICE_EMAIL}</MenuItem>
        </Menu>
        <Title>SHOP USED EQUIPMENT</Title>
        <Menu>
            <GotoMenu to={ROUTES.CURRENT_INVENTORY}><MenuItem>Shop All Equipment</MenuItem></GotoMenu>
            <StaticQuery
            query={graphql`
                query AttachmentMenuQuery {
                    allTaxonomyTermCategory {
                        edges {
                            node {
                                name
                                path {
                                  alias
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <div>
                {data.allTaxonomyTermCategory.edges.map(({ node }, i) => (
                  <GotoMenu key={i} to={`${node.path.alias}/`}>
                      <MenuItem>{node.name}</MenuItem>
                  </GotoMenu>
                ))}
              </div>
            )}
            />
        </Menu>
        <Number>{process.env.GATSBY_PHONE_NUMBER}</Number>
    </Container>
  )

  export default AttachmentMenu