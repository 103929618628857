import React from "react"
import Layout from "../components/layout"
import {graphql} from "gatsby";
import SEO from "../components/seo"
import {Container, Wrapper, Article, Title, Description, Filter, Left, Path, Right, Label, Sort, GotoMenu} from "../styles/attachmentsStyles"
import {List, Item, Picture, Quick, MiniTitle, Price} from "../styles/attachmentsStyles"
import {formatPrice, getImage} from "../helpers";
import SideMenu from "../components/attachmentMenu"
import {ROUTES} from "../routes";

export default ({data: {taxonomyTermCategory}}) => {
  const {name, field_meta_tag, description, field_closure, relationships: { node__equipment: equipment }} = taxonomyTermCategory;

  return (
    <Layout>
      <SEO {...field_meta_tag} />
      <div style={{display: "flex", justifyContent: "center"}}>
        <Filter>
          <Left>
            <Path><GotoMenu to="/">HOME</GotoMenu> / <GotoMenu to={ROUTES.CURRENT_INVENTORY}>CURRENT
              INVENTORY</GotoMenu> / <b>{name}</b></Path>
          </Left>
          
        </Filter>
      </div>
      <Container>
        <Wrapper>
          <SideMenu style={{margin: '0'}}/>
          <Article>
            <Title>{name}</Title>
            <Description dangerouslySetInnerHTML={{ __html: description ? description.processed : '' }} />
            <List>
              {equipment ? equipment.map((item, index) => {
                let image = getImage(item, 0);
                return (
                  <Item key={index}><GotoMenu to={`${item.path.alias}/`}>
                    <Picture alt={image.alt} title={image.title} src={image.url} />
                    <Label>{item.relationships.field_category.name}</Label>
                    <MiniTitle>{item.title}</MiniTitle>
                    <Price>${formatPrice(item.field_price, 0)}</Price></GotoMenu>
                  </Item>
                );
              }) : null}
            </List>
            <Description dangerouslySetInnerHTML={{ __html: field_closure ? field_closure.processed : '' }} />
          </Article>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query CategoryQuery($slug: String!) {
    taxonomyTermCategory(fields: { slug: { eq: $slug } }) {
      name
      field_meta_tag {
        title
        description
      }
      description {
        processed
      }
      field_closure {
        processed
      }
      relationships {
        node__equipment {
          drupal_internal__nid
          title
          field_price
          path {
            alias
          }
          relationships {
            field_category {
              name
              relationships {
                field_image {
                  field_media_image {
                    title
                    alt
                  }
                  relationships {
                    field_media_image {
                      url
                    }
                  }
                }
              }
            }
            field_images {
              field_media_image {
                title
                alt
              }
              relationships {
                field_media_image {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

