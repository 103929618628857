import ExampleImg from "../images/example.jpg"

export const formatPrice = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}

export const getImage = (item, index = 0) =>{
  let image = {};

  if (item.relationships && item.relationships.field_images) {
    if (item.relationships.field_images.length) {
      if (item.relationships.field_images[index].field_media_image) {
        image = item.relationships.field_images[index].field_media_image;
      }

      if (item.relationships.field_images[index]) {
        image.url = item.relationships.field_images[index].relationships.field_media_image.url;
      }
    }
  }

  if (!image.url && item.relationships.field_image) {
    if (item.relationships.field_image.field_media_image) {
      image = item.relationships.field_image.field_media_image;
    }

    image.url = item.relationships.field_image.relationships.field_media_image.url;
  }

  if (!image.url && item.relationships.field_category) {
    if (item.relationships.field_category.relationships.field_image) {
      image = item.relationships.field_category.relationships.field_image.field_media_image;
    }

    image.url = item.relationships.field_category.relationships.field_image.relationships.field_media_image.url;
  }

  if (item.name) {
    if (!image.alt) {
      image.alt = item.name;
    }
    if (!image.title) {
      image.title = item.name;
    }
  }

  return image;
}