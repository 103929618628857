import styled from "styled-components";
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 1160px) {
        width: 100%;
      }
    @media (max-width: 769px) {
      flex-direction: column-reverse;
      width: 100%;
      align-items: center;
      margin: 0;
    }
`;

const Article = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    align-text: center;
    align-self: auto;
    @media (max-width: 769px) {
      width: 100%;
    }
`;

const Title = styled.h1`
  display: none;
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  margin-left: 2%;
  text-align: left;

  @media (max-width: 769px) {
    font-size: 20px;
  }
`;

const List = styled.ul`
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  list-style: none;
  margin-left: 2%;
  margin-top: 0px;
  @media (max-width: 769px) {
    margin: 0;
    justify-content: center;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  max-width: 31%;
  margin: 1%;
  margin-top: 0px;
  margin-bottom: 5%;
  cursor: pointer;
  a:-webkit-any-link {
    
    cursor: pointer;
    text-decoration: none;
}
  @media (max-width: 769px) {
    max-width: 45%;
    margin: 2.5%;
    margin-bottom: 10%;
  }
`;

const Price = styled.div`
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  color: #000;
`;

const Picture = styled.img`
  height: auto;
  margin: 0;
  object-fit: cover;
`;

const Quick = styled.div`
  font-size: 14px;
  color: #000;
`;

const Description = styled.div`
  font-size: 14px;
  margin-left: 2%;
`;

const MiniTitle = styled.div`
  font-size: 14px;
  color: #1E73BE;
  max-width: fit-content;
  :hover {
      color: #000;
  }
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1250px;
  width: 80%;
  margin: 40px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
    
  }
`;

const Left = styled.div`
  align-items: center;
  text-align: center;
  @media (max-width: 769px) {
    padding-bottom: 20px;
  }
`;

const Path = styled.div`
  font-size: 16px;
  letter-spacing: 4px;
  a:-webkit-any-link {
    color: grey;
    text-decoration: none;
    :hover {
        color: #000;
    }
  }
`;

const Right = styled.div`
  margin-right: 1%;
  display: flex;
  flex-direction: row;
  @media (max-width: 769px) {
    padding-top: 20px;
  }
`;

const Label = styled.div`
  margin-right: 10px;
  font-size: 14px;
  color: #000;
`;

const Sort = styled.select`
  box-shadow: inset 0px -1.4em 1em 0px rgb(0 0 0 / .02);
  :hover {
    box-shadow: inset 0px -1.4em 1em 0px rgb(0 0 0 / 0);
  }
`;

const GotoMenu = styled(Link)`
  color: grey;
  text-decoration: none;
  :hover {
    color: #000;
  }
`;

export {
    Container,
    Article,
    Title,
    Wrapper,
    List,
    Item,
    Picture,
    Price,
    Quick,
    Description,
    MiniTitle,
    Filter, 
    Path, 
    Right, 
    Left,
    Label, 
    Sort,
    GotoMenu,
}